import { WhatsAppOutlined } from "@ant-design/icons";
import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Steps from "antd/lib/steps";
import Typography from "antd/lib/typography";
import React from "react";

import config from "../../../config";
import SgqrApps from "../../../images/sgqr-apps.png";
import SgqrImage from "../../../images/sgqr.png";

const { Text, Paragraph } = Typography;
const { Step } = Steps;

const whatsAppLink = config.whatsapp.support;

const SgqrPayment = ({ order }) => {
  return (
    <Col>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row justify="center">
          <Steps direction="vertical" current={-1}>
            <Step
              title="Open your preferred Banking App"
              subTitle={
                <img
                  src={SgqrApps}
                  alt="SGQR Supported Apps"
                  style={{ width: "100%", height: "auto" }}
                />
              }
            />
            <Step title="Scan the QR Code" />
            <Step
              title="Scan QR Code"
              subTitle={
                <Paragraph>
                  <img
                    src={SgqrImage}
                    alt="SGQR Code"
                    style={{ width: 200, height: 200 }}
                  />
                  <Paragraph>
                    or pay to UEN{" "}
                    <Text mark copyable>
                      201935705H
                    </Text>
                  </Paragraph>
                </Paragraph>
              }
            />
            <Step
              title="Pay"
              subTitle={
                <Paragraph>
                  Copy Mmucho Order Number{" "}
                  <Text mark copyable>
                    {order.orderNo}
                  </Text>{" "}
                  and Order Amount{" "}
                  <Text mark copyable>
                    {order.amount / 100}
                  </Text>{" "}
                  and Paste in Payment.
                </Paragraph>
              }
            />
            <Step title="Confirm Payment" />
            <Step
              title="Share Details"
              subTitle={
                <>
                  <Paragraph>Send Receipt and Order Number here:</Paragraph>
                  <Button
                    type="link"
                    size="large"
                    style={{ background: "#25d366" }}
                    icon={
                      <WhatsAppOutlined
                        style={{ color: "white", fontSize: 16 }}
                      />
                    }
                    href={whatsAppLink}
                    target="_blank"
                  >
                    Whatsapp
                  </Button>
                </>
              }
            />
          </Steps>
        </Row>
      </Space>
    </Col>
  );
};

export default SgqrPayment;
