import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

// import LogoSection from "../../../components/LogoSection";
import ResponsiveTitle from "../../../components/ResponsiveTitle";
import { resetCart } from "../../../state/cart";
import OrderSummary from "../OrderSummary";
import SgqrPayment from "./SgqrPayment";

const { Paragraph } = Typography;

const WetMarketSuccess = ({
  location: {
    state: { order },
  },
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!order) navigate("/");
    else dispatch(resetCart());
  }, [dispatch, order]);

  if (!order) return null;

  return (
    <Row justify="center" gutter={[40, 40]}>
      <Col md={12}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <ResponsiveTitle>{t("summary.sgqrPayment")}</ResponsiveTitle>
          <Row justify="center">
            <Paragraph>
              {t("success.confirm")} Follow the steps below to pay via SGQR.
            </Paragraph>
          </Row>
          <SgqrPayment order={order} />
        </Space>
      </Col>
      <Col md={12}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <ResponsiveTitle>{t("summary.order")}</ResponsiveTitle>
          <Row justify="center">
            <OrderSummary order={order} />
          </Row>
          <Row justify="center">
            <Button
              size="large"
              onClick={() => navigate(`app/orders/${order.id}`)}
            >
              {t("success.viewOrder")}
            </Button>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};

export default WetMarketSuccess;
